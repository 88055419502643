export const environment = {
  production: false,
  urlApi: 'https://kwau3y5pa7.execute-api.us-east-1.amazonaws.com/dev/api/v1',
  firebase: {
    projectId: "varever-certificates",
    appId: "1:722018758567:web:3efaf4612a272ff89f9082",
    storageBucket: "varever-certificates.appspot.com",
    apiKey: "AIzaSyCyTk0_-KWcz8M_OsDbKtqNBL1fCu6Dqw4",
    authDomain: "varever-certificates.firebaseapp.com",
    messagingSenderId: "722018758567",
    measurementId: "G-3ZPDZMV3M9"
  }
};
